import { useState, useEffect } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';

export function ManageUsersModal({ show, onHide, backendAPI }) {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null); // State to manage user CRUD
    const [isAddMode, setIsAddMode] = useState(false); // State to track if it's add mode

    useEffect(() => {
        if(show) {
            fetchUsers();
        }
    }, [show]);

    async function fetchUsers() {
        try {
            setUsers([]);
            const response = await backendAPI.getUsers();
            if (response && response.data) {
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    async function handleUserSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        let email = formData.get('email');
        let is_admin = formData.get('isAdmin') === 'on' ? 'True' : 'False';
        let password = "";
        if (isAddMode) {
            password = formData.get('password');
        }

        // const userData = {
        //     email: formData.get('email'),
        //     is_admin: formData.get('isAdmin') === 'on',
        // };

        // if (isAddMode) {
        //     // Add password field if in add mode
        //     userData.password = formData.get('password');
        // }
        // console.log("it is: " + userData);
        // console.log(userData);

        try {
            if (selectedUser) {
                await backendAPI.updateUser(selectedUser.id, email, is_admin);
            } else {
                await backendAPI.addUser(email, password, is_admin);
            }
            fetchUsers();
            setSelectedUser(null);
            setIsAddMode(false); // Reset add mode
            onHide();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    }

    async function deleteUser(userId) {
        try {
            await backendAPI.deleteUser(userId);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    }

    function openAddModal() {
        setSelectedUser(null);
        setIsAddMode(true); // Enable add mode
    }

    function openEditModal(user) {
        setSelectedUser(user);
        setIsAddMode(false); // Disable add mode for edit
    }

    function handleCancel() {
        setSelectedUser(null);
        setIsAddMode(false);
    }

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Manage Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Is Admin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.is_admin ? 'Yes' : 'No'}</td>
                                <td>
                                    <Button variant="warning" onClick={() => openEditModal(user)}>Edit</Button>
                                    <Button variant="danger" onClick={() => deleteUser(user.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <hr className="my-3" />

                {(selectedUser == null && !isAddMode) && <Button onClick={openAddModal}>Add User</Button>}
                {/* Add a new user */}
                {(isAddMode) && (
                    <>
                        <h5>Add new user</h5>
                        <Form onSubmit={handleUserSubmit} className="mt-4">
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    defaultValue={selectedUser ? selectedUser.email : ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formPassword" className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formIsAdmin" className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    name="isAdmin"
                                    label="Is Admin"
                                    defaultChecked={false}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-center mt-4">
                                <Button variant="primary" type="submit" className="me-2">
                                    Create User
                                </Button>
                                <Button variant="secondary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </>

                )}

                {/* Edit a user */}
                {(selectedUser !== null) && (
                    <>
                        <h5>Edit user {selectedUser ? selectedUser.email : ''}</h5>
                        <Form onSubmit={handleUserSubmit} className="mt-4">
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    defaultValue={selectedUser ? selectedUser.email : ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formIsAdmin" className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    name="isAdmin"
                                    label="Is Admin"
                                    defaultChecked={selectedUser ? selectedUser.is_admin : false}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-center mt-4">
                                <Button variant="primary" type="submit" className="me-2">
                                    Update User
                                </Button>
                                <Button variant="secondary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
