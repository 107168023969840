import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ImageModal = ({ imageSrc, altText, children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Inline styles for the grey overlay
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent grey
    zIndex: 1040, // Below the modal but above other content
  };

  // Create a portal to render the image modal outside the parent modal
  const imageModalContent = (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <img
          src={imageSrc}
          style={{ width: '100%', height: 'auto' }}
          alt={altText || 'Full size image'}
        />
      </Modal.Body>
    </Modal>
  );

  return (
    <div>
      {/* Clickable image or content to trigger modal */}
      <div onClick={handleShow} style={{ cursor: 'pointer' }}>
        {children}
      </div>

      {/* Grey out the background when the modal is open */}
      {show && <div style={overlayStyles}></div>}

      {/* Render the modal using a portal */}
      {show && ReactDOM.createPortal(imageModalContent, document.body)}
    </div>
  );
};

export default ImageModal;
