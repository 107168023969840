import axios from 'axios';

export class BackendAPI {
	constructor(base_url, onUnauthorized) {
		this.interface = axios.create({
			baseURL: base_url,
			timeout: 20000
		});

		this.onUnauthorized = onUnauthorized;

		this.interface.interceptors.request.use((config) => {
			const token = localStorage.getItem('authToken');
			if (token) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			return config;
		});

		this.interface.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && error.response.status === 401 && !error.config.url.includes('/login')) {
					localStorage.removeItem('authToken');
					if (typeof onUnauthorized === 'function') {
						this.onUnauthorized();
					}
				}
				return Promise.reject(error);
			}
		);
	}

	async getUsers() {
		const response = await this.interface.get(`/admin/user`);
		return response;
	}

	async updateUser(id, email, is_admin) {
		const response = await this.interface.put(`/admin/user/${id}`, { email, is_admin });
		console.log(response);
		return response;
	}

	async addUser(email, password, is_admin) {
		const response = await this.interface.post(`/admin/user`, { email, password, is_admin });
		return response
	}

	async deleteUser(id) {
		const response = await this.interface.delete(`/admin/user/${id}`);
		return response;
	}

	async getDevices(pageNum = 0, pageSize = 10) {
		const response = await this.interface.get(`/device?offset=${pageNum * pageSize}&limit=${pageSize}`);
		return response;
	}

	async createDevice(device) {
		const response = await this.interface.post(`/device`, device);
		return response;
	}

	async updateDevice(device) {
		const response = await this.interface.put(`/device/${device.id}`, device);
		return response;
	}

	async deleteDevice(device_id) {
		const response = await this.interface.delete(`/device/${device_id}`);
		return response;
	}

	async getDeviceEventsByDeviceId(device_id, offset, limit) {
		const response = await this.interface.get(`/device_event?device_id=${device_id}&offset=${offset}&limit=${limit}`);
		return response;
	}

	async getDeviceEvents(offset, limit) {
		const response = await this.interface.get(`/device_event?offset=${offset}&limit=${limit}`);
		// console.log(response);
		return response;
	}

	async getDeviceEventsWithFilters(location, vehicleClass, direction, leftRight, startDate, endDate, offset, limit) {
		// Start building the query string
		let query = `/device_event?offset=${offset}&limit=${limit}`;
		
		// Append filters to the query string if they are present
		if (location) {
			query += `&location=${encodeURIComponent(location)}`;
		}
		if (vehicleClass) {
			query += `&vehicle_class=${encodeURIComponent(vehicleClass)}`;
		}
		if (direction) {
			query += `&direction=${encodeURIComponent(direction)}`;
		}
		if (leftRight) {
			query += `&left_right=${encodeURIComponent(leftRight)}`;
		}
		if (startDate) {
			query += `&start_date=${encodeURIComponent(startDate.toISOString())}`; // Assuming startDate is a Date object
		}
		if (endDate) {
			query += `&end_date=${encodeURIComponent(endDate.toISOString())}`; // Assuming endDate is a Date object
		}
		console.log(query);
		// Make the API call with the constructed query string
		const response = await this.interface.get(query);
		
		return response;
	}

	async getDeviceEventReport({ devices, startDate, endDate, bucketSize }) {
		const response = await this.interface.post('device_event/report', {
			devices,
			start_date: startDate.toISOString(),
			end_date: endDate.toISOString(),
			bucket_size: bucketSize,
		});
		console.log(response);
		return response;
	}

	async getMedia(media_id) {
		const response = await this.interface.get(`/media?id=${media_id}`);
		return response;
	}

	async isLoggedIn() {
		const token = localStorage.getItem('authToken');
		if (token) {
			try {
				const response = await this.interface.get('/auth/validateToken', {
					headers: { Authorization: `Bearer ${token}` },
				});
				if (response.status === 200) {
					return true;
				} else {
					localStorage.removeItem('token');
				}
			} catch (error) {
				return false;
			}
		}
	}



	async getZones() {
		const response = await this.interface.get('/zone');
		return response;
	}

	async createZone(zone) {
		const response = await this.interface.post('/zone', zone);
		return response;
	}

	async updateZone(zone) {
		const response = await this.interface.put(`/zone/${zone.id}`, zone);
		return response;
	}

	async deleteZone(zoneId) {
		const response = await this.interface.delete(`/zone/${zoneId}`);
		return response;
	}

	async getZoneDevice(zone_id) {
		const response = await this.interface.get(`/zone_device?zone_id=${encodeURIComponent(zone_id)}`);
		return response;
	}

	async getDevicesNotInZone(zone_id, pageNum = 0, pageSize = 10) {
		const response = await this.interface.get(`/zone_device/not_in_zone?zone_id=${zone_id}&offset=${pageNum * pageSize}&limit=${pageSize}`);
		return response;
	}

	async addZoneDevice(zone_id, device_id, roi_id, event_type) {
		const response = await this.interface.post('/zone_device', { 
			zone_id,
			device_id,
			roi_id,
			event_type
		});
		return response;
	}

	async deleteZoneDevice(zone_device_id) {
		const response = await this.interface.delete(`/zone_device/${encodeURIComponent(zone_device_id)}`);
		return response;
	}
	
	async getTransits(zone_id, startDate, endDate, vln, status, offset = 0, limit = 10) {
		let query = `/zone_transit?offset=${offset}&limit=${limit}`;
		
		if (zone_id) {
			query += `&zone_id=${encodeURIComponent(zone_id)}`;
		}
		if (status) {
			query += `&status=${encodeURIComponent(status)}`;
		}
		if (startDate) {
			query += `&start_date=${encodeURIComponent(startDate.toISOString())}`;
		}
		if (endDate) {
			query += `&end_date=${encodeURIComponent(endDate.toISOString())}`;
		}
		if (vln) {
			query += `&vln=${encodeURIComponent(vln)}`;
		}
		if (status) {
			query += `&status=${encodeURIComponent(status)}`;
		}
		
		const response = await this.interface.get(query);
		return response;
	}

	async getTransitById(transitId) {
		const response = await this.interface.get(`/zone_transit/${transitId}`);
		return response;
	}
	
	async getTransitReport(zone_id, startDate, endDate, vln_search, status_filter, class_filter) {
		
		let query = `/zone_transit/report?zone_id=${zone_id}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`;
		if (vln_search) {
			query += `&vln=${encodeURIComponent(vln_search)}`;
		}
		if (status_filter) {
			query += `&status=${encodeURIComponent(status_filter)}`;
		}
		if (class_filter) {
			query += `&class=${encodeURIComponent(class_filter)}`;
		}
		const response = await this.interface.get(query, { responseType: 'blob', timeout: 120000 });
		return response;
	}

	async getTransitStats() {
		const response = await this.interface.get('/zone_transit/stats_today');
		return response;
	}

	async getPossibleTransitMatches(transitId) {
		const response = await this.interface.get(`/zone_transit/possible_matches?transit_id=${transitId}`);
		return response;
	}

	async matchTransits(entryTransitId, exitTransitId) {
		try {
			const response = await this.interface.post('/zone_transit/match_transit', {
				entry_transit_id: entryTransitId,
				exit_transit_id: exitTransitId
			});
			return response;
		} catch (error) {
			if (error.response) {
				return error.response;
			} else {
				throw error;
			}
		}
	}
}
