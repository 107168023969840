// TransitTable.js
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Spinner, Button } from "react-bootstrap";
import { TransitModal } from './TransitModal.js';
import { TransitFilterModal } from './TransitFilterModal.js';
import { TransitReport } from './TransitReport.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChartBar } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'

export function TransitTable({ backendAPI, websocket, zones }) {

    const [transits, setTransits] = useState([]);
    const [totalTransits, setTotalTransits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const transitsRef = useRef(transits);
    const totalTransitsRef = useRef(totalTransits);
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({});
    const [filterModalShow, setFilterModalShow] = useState(false);

    const [reportModalShow, setReportModalShow] = useState(false);

    useEffect(() => {
    }, [zones]);

    useEffect(() => {
        websocket.registerEventCallback(handleWebsocketTransitEvent, 'transit_event');
        return () => websocket.deregisterEventCallback(handleWebsocketTransitEvent);
    }, [websocket]);

    useEffect(() => {
        async function fetchData(page, size) {
            setLoading(true);
            const vln_search = null;
            const status_filter = null;
            try {
                const response = await backendAPI.getTransits( //getDeviceEventsWithFilters(
                    filters.zone_id,
                    filters.startDate,
                    filters.endDate,
                    vln_search,
                    filters.status,
                    (currentPage - 1) * pageSize,
                    pageSize
                );
                const updatedTransits = response.data.zone_transits.map(data => {
                    let status = data.status;
                    if (status === 'CLOSED') {
                        if (data.entry_device_event && !data.exit_device_event) {
                            data.status = 'INCOMPLETE';
                        } else if (data.exit_device_event && !data.entry_device_event) {
                            data.status = 'INCOMPLETE';
                        }
                    } 
                    // else if (status === 'EXPIRED') {
                    //     data.status = 'INCOMPLETE';
                    // }
                    return { ...data, status };
                });
                setTransits(response.data.zone_transits);
                setTotalTransits(response.data.total);
            } catch (error) {
                console.error('Error getting transits:', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchData(currentPage, pageSize);
    }, [backendAPI, filters, currentPage]);

    useEffect(() => {
        transitsRef.current = transits;
    }, [transits]);

    useEffect(() => {
        totalTransitsRef.current = totalTransits;
    }, [totalTransits]);

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    function handleWebsocketTransitEvent(data) {
        // console.log("Table got a transit event:", data);
        // if(data['event_type'] == 'delete') {
        //     setTransits((prevTransits) => prevTransits.filter(transit => transit.id !== data.id));
        //     setTotalTransits((prevTotal) => prevTotal - 1);
        //     return;
        // }
        // setTransits((prevTransits) => {
        //     // TODO: Filter transits based on filters
        //     // if (filters.zone_id && data.zone_id !== filters.zone_id) return prevTransits;
        //     // if (filters.status && data.status !== filters.status) return prevTransits;
        //     // if (filters.startDate && new Date(data.event_time) < new Date(filters.startDate)) return prevTransits;
        //     // if (filters.endDate && new Date(data.event_time) > new Date(filters.endDate)) return prevTransits;
        //     const existingTransit = prevTransits.find(transit => transit.id === data.id);
        //     if (existingTransit) {
        //         return prevTransits.map(transit => transit.id === data.id ? data : transit);
        //     } else {
        //         let newTransits = [data, ...prevTransits];
        //         newTransits = newTransits.slice(0, pageSize);
        //         setTotalTransits((prevTotal) => prevTotal + 1);
    
        //         return newTransits;
        //     }
        // });
    }
    

    const applyFilters = (filterParams) => {
        setFilters(filterParams);
    };

    const formatDateTime = (datetimeStr) => {
        if (!datetimeStr) return '---';
        const date = new Date(datetimeStr);
        const fullDate = date.toLocaleDateString(); 
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); // 24-hour format e.g., "14:30"
        return { fullDate, time };
    };

    return (
        <div >
            <div style={{ textAlign: "right" }}>
                <FontAwesomeIcon
                    icon={faFilter}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFilterModalShow(true)}
                />
                {<FontAwesomeIcon
                    icon={faChartBar}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setReportModalShow(true)}
                />}
            </div>
            <TransitFilterModal
                show={filterModalShow}
                onHide={() => setFilterModalShow(false)}
                onApply={applyFilters}
                zones={zones}
            />
            <Modal show={reportModalShow} onHide={() => setReportModalShow(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Transit Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransitReport backendAPI={backendAPI} zones={zones} /> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setReportModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <TransitReportModal
                // show={reportModalShow}
                // onHide={() => setReportModalShow(false)}
                backendAPI={backendAPI}
                zones={zones}
            /> */}
            <Row>
                {/* Table Header */}
                <Col className="mt-1 mb-1" xs={3} lg={2}>Zone</Col>
                <Col className="mt-1 mb-1" xs={2} lg={1}>VLN</Col>
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={1}>Class</Col>
                <Col className="mt-1 mb-1" xs={2} lg={3}>Entry</Col>
                <Col className="mt-1 mb-1" xs={2} lg={3}>Exit</Col>
                <Col className="mt-1 mb-1" xs={2} lg={1}>Status</Col>
                <Col className="mt-1 mb-1" xs={1} lg={1}></Col>
            </Row>
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : transits && transits.map((transit, index) => {
                const entryEventTime = transit.entry_device_event ? formatDateTime(transit.entry_device_event.event_time) : { fullDate: '---', time: '---' };
                const exitEventTime = transit.exit_device_event ? formatDateTime(transit.exit_device_event.event_time) : { fullDate: '---', time: '---' };

                return (
                    <div key={index} style={{ backgroundColor: index % 2 === 0 ? '#101014' : '#2d2d39' }} className="transit">
                        <Row key={index}>
                            <Col className="text-truncate d-block mt-1 mb-1" xs={3} lg={2}>
                                {transit.zone_name}
                            </Col>
                            <Col className="mt-1 mb-1" xs={2} lg={1}>
                                {transit.entry_device_event ? transit.entry_device_event.vln : transit.exit_device_event ? transit.exit_device_event.vln : "---"}
                            </Col>
                            <Col className="d-none d-sm-inline text-truncate d-block mt-1 mb-1" xs={0} lg={1}>
                                {
                                    (transit.entry_device_event ? transit.entry_device_event.vehicle_class : '---')
                                    + ' / ' + (transit.exit_device_event ? transit.exit_device_event.vehicle_class : '---')
                                }
                            </Col>
                            <Col className="text-truncate d-block mt-1 mb-1" xs={2} lg={3}>
                                <span className="d-none d-sm-inline">{entryEventTime.fullDate}</span>
                                <span>{' '}</span>
                                <span>{entryEventTime.time}</span>
                            </Col>
                            <Col className="text-truncate d-block mt-1 mb-1" xs={2} lg={3}>
                                <span className="d-none d-sm-inline">{exitEventTime.fullDate}</span>
                                <span>{' '}</span>
                                <span>{exitEventTime.time}</span>
                            </Col>
                            <Col className="text-truncate d-block mt-1 mb-1" xs={2} lg={1}>
                                {transit.status}
                            </Col>
                            <Col className="text-truncate d-block mt-1 mb-1" xs={1} lg={1}>
                                {/* <TransitModal transit={transit}/> */}
                                <TransitModal zone={null} backendAPI={backendAPI} transit={transit}/>
                                {/* <TransitModal device={null} backendAPI={backendAPI} websocket={websocket} liveDeviceEventUpdate={false} device_event={device_event}/> */}
                            </Col>
                            {/* <Col>
                                <TransitModal transit={transit} />
                            </Col> */}
                        </Row>
                    </div>
                );
            })}
            {/* Pagination Controls */}
            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={(currentPage * pageSize) >= transits ? transits.length : 0}>Next</button>
        </div>
    );
}
