// TransitFilterModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";

export function TransitFilterModal({ show, onHide, onApply, zones }) {
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
    }, [zones]);

    const applyFilters = () => {
        onApply({
            zone_id: selectedZone.id,
            status: selectedStatus,
            startDate,
            endDate
        });
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Transits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formZone">
                        <Form.Label>Zone</Form.Label>
                        <Form.Control as="select" value={selectedZone} onChange={(e) => setSelectedZone(e.target.value)}>
                            <option value="">Select Zone</option>
                            {zones.map((zone, index) => (
                                <option key={index} value={zone.id}>{zone.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {/* <Form.Group controlId="formStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                            <option value="">Select Status</option>
                            <option value="OPEN">Open</option>
                            <option value="CLOSED">Closed</option>
                            <option value="EXPIRED">Expired</option>
                        </Form.Control>
                    </Form.Group> */}
                    <Form.Group controlId="formStartDate">
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </Form.Group>
                    <Form.Group controlId="formEndDate">
                        <Form.Label>End Date</Form.Label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={applyFilters}>Apply Filters</Button>
            </Modal.Footer>
        </Modal>
    );
}
