import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RootRedirect = ({ backendAPI }) => {
    const navigate = useNavigate();

    useEffect(() => {
    const checkToken = async () => {
        try {
            if (await backendAPI.isLoggedIn())
                navigate('/dashboard');
            else
                navigate('/login');
        } catch (error) {
            console.error('Error checking token:', error);
            navigate('/login');
        }
    };

    checkToken();
    }, [navigate, backendAPI]);

    return <div>Loading...</div>;
};

export default RootRedirect;
