import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

const backendAPI = props.backendAPI;

    useEffect(() => {
        const checkToken = async () => {
            try {
                if (await backendAPI.isLoggedIn()) {
                    navigate('/dashboard');
                }
            } catch (error) {
                console.error('Error checking token:', error);
            }
        };

        checkToken();
    }, [backendAPI]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await backendAPI.interface.post('/auth/login', { email, password });
            localStorage.setItem('authToken', response.data.token);
            setMessage('Logged in successfully');
            navigate('/dashboard');
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.error);
            } else if (error.request) {
                setMessage('No response was received from the server');
            } else {
                setMessage('Error in making the request');
            }
            console.error('Error', error.message);
        }
    };

    return (
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
                <Col md={6} lg={4} className="mx-auto">
                    <Card className="p-4">
                        <h2>Login</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">Login</Button>
                        </Form>
                        {message && <p>{message}</p>}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
