import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../../assets/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

export function ConfirmationDialog({ title, body, show, onHide, onConfirm, callback_user_data }) {

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    async function confirm() {
        setIsLoading(true);
        let res = await onConfirm(callback_user_data);
        if(res) {
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
            setIsError(true);
        }
        setIsLoading(false);
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <div>
                    <Spinner animation="border" variant="primary" />
                    <p>Processing...</p>
                </div>
            );
        } else if (isSuccess) {
            return (
                <div>
                    <p>Action was successful!</p>
                </div>
            );
        } else if (isError) {
            return (
                <div>
                    <p>SOMETHING WENT WRONG!</p>
                </div>
            );
        } else {
            return (
                body
            );
        }
    };

    return (
    <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{whiteSpace: "pre"}}>
            {renderContent()}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Cancel
            </Button>
            <Button variant="primary" onClick={confirm}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
    );
}