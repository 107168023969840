import io from 'socket.io-client';

export class Websocket {
    constructor(server_url) {
        this.socket = io(server_url);
        this.eventCallbacks = {};
    }

    // Dynamically register the event type and its callback
    registerEventCallback(callback, event_type) {
        if (!this.eventCallbacks[event_type]) {
            this.eventCallbacks[event_type] = [];
            this.socket.on(event_type, (data) => {
                console.log(`Got websocket event: ${event_type}`);
                this.eventCallbacks[event_type].forEach(callback_fn => {
                    callback_fn(data);
                });
            });
        }
        this.eventCallbacks[event_type].push(callback);
    }

    // Deregister a callback for a specific event type
    deregisterEventCallback(callback, event_type) {
        if (this.eventCallbacks[event_type]) {
            this.eventCallbacks[event_type] = this.eventCallbacks[event_type].filter(cb => cb !== callback);
            if (this.eventCallbacks[event_type].length === 0) {
                this.socket.off(event_type); // Remove the listener when there are no callbacks left
            }
        }
    }

    disconnect() {
        this.socket.disconnect();
    }
}

export default Websocket;
