import React, { useState } from 'react';
import { Modal, Button, Form, Dropdown, DropdownButton, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function DeviceEventFilterModal({ show, onHide, onApply, devices }) {
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedVehicleClass, setSelectedVehicleClass] = useState('');
    const [selectedDirection, setSelectedDirection] = useState('');
    const [selectedLeftRight, setSelectedLeftRight] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const vehicleClassOptions = ['light', 'minibus', 'bus', 'heavy'];

    const applyFilters = () => {
        onApply({
            location: selectedLocation,
            vehicleClass: selectedVehicleClass,
            direction: selectedDirection,
            leftRight: selectedLeftRight,
            startDate,
            endDate,
        });
        onHide();
    };

    const clearAllFilters = () => {
        setSelectedLocation('');
        setSelectedVehicleClass('');
        setSelectedDirection('');
        setSelectedLeftRight('');
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Device Events</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Location Filter */}
                    <Form.Group as={Row} controlId="formLocation" className="mb-3">
                        <Form.Label column sm={4}>Location</Form.Label>
                        <Col sm={8}>
                            <DropdownButton
                                id="dropdown-location"
                                title={selectedLocation || "Select Location"}
                                onSelect={(e) => setSelectedLocation(e)}
                            >
                                {devices.map((device, index) => (
                                    <Dropdown.Item key={index} eventKey={device.location}>
                                        {device.location}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                    </Form.Group>

                    <hr className="my-3" />

                    {/* Vehicle Class Filter */}
                    <Form.Group as={Row} controlId="formVehicleClass" className="mb-3">
                        <Form.Label column sm={4}>Vehicle Class</Form.Label>
                        <Col sm={8}>
                            <DropdownButton
                                id="dropdown-vehicle-class"
                                title={selectedVehicleClass || "Select Vehicle Class"}
                                onSelect={(e) => setSelectedVehicleClass(e)}
                            >
                                {vehicleClassOptions.map((vehicleClass, index) => (
                                    <Dropdown.Item key={index} eventKey={vehicleClass}>
                                        {vehicleClass}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                    </Form.Group>

                    <hr className="my-3" />

                    {/* Direction Filter */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>Direction</Form.Label>
                        <Col sm={8}>
                            <Form.Check
                                type="checkbox"
                                label="Approaching"
                                name="directionOptions"
                                value="approaching"
                                checked={selectedDirection === 'approaching'}
                                onChange={(e) => setSelectedDirection(selectedDirection === 'approaching' ? '' : 'approaching')}
                            />
                            <Form.Check
                                type="checkbox"
                                label="Departing"
                                name="directionOptions"
                                value="departing"
                                checked={selectedDirection === 'departing'}
                                onChange={(e) => setSelectedDirection(selectedDirection === 'departing' ? '' : 'departing')}
                            />
                        </Col>
                    </Form.Group>

                    <hr className="my-3" />

                    {/* Left/Right Filter */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>Left/Right</Form.Label>
                        <Col sm={8}>
                            <Form.Check
                                type="checkbox"
                                label="Left"
                                name="leftRightOptions"
                                value="left"
                                checked={selectedLeftRight === 'left'}
                                onChange={(e) => setSelectedLeftRight(selectedLeftRight === 'left' ? '' : 'left')}
                            />
                            <Form.Check
                                type="checkbox"
                                label="Right"
                                name="leftRightOptions"
                                value="right"
                                checked={selectedLeftRight === 'right'}
                                onChange={(e) => setSelectedLeftRight(selectedLeftRight === 'right' ? '' : 'right')}
                            />
                        </Col>
                    </Form.Group>

                    <hr className="my-3" />

                    {/* Date-Time Pickers */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>Start Date and Time</Form.Label>
                        <Col sm={8}>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                placeholderText="Select Start Date and Time"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>End Date and Time</Form.Label>
                        <Col sm={8}>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                placeholderText="Select End Date and Time"
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={clearAllFilters}>
                    Clear All Filters
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={applyFilters}>
                    Apply Filters
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
