// ZonesTable.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import TransitModal from './TransitModal';
import { ZoneSettingsModal } from './ZoneSettingsModal'; // Assuming this is similar to DeviceSettingsModal
import { ConfirmationDialog } from './common/ConfirmationDialog'; // Reuse for delete confirmation

export function ZonesTable({ backendAPI, zones, zoneChangedCallback, websocket }) {
    // const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [showTransitModal, setShowTransitModal] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [transitStats, setTransitStats] = useState([]);

    useEffect(() => {
    }, [zones]);

    useEffect(() => {
        websocket.registerEventCallback(handleWebsocketTransitEvent, 'transit_event');
        return () => websocket.deregisterEventCallback(handleWebsocketTransitEvent);
    }, [websocket]);

    useEffect(() => {
        fetchTransitStats();
    }, []);

    async function fetchTransitStats() {
        try {
            const response = await backendAPI.getTransitStats();
            console.log(response.data);
            setTransitStats(response.data);
        } catch (error) {
            console.error('Error getting transit stats:', error);
        }
    };

    async function handleEditZone(zone) {
        try {
            const response = await backendAPI.updateZone(zone);
            if (response.status === 200) {
                zoneChangedCallback();
            }
            console.log("Update is complete");
            return response;
        } catch (error) {
            console.error('Error editing zone:', error);
            return null;
        }
    }

    async function handleAddZone(zone) {
        try {
            const response = await backendAPI.createZone(zone);
            if (response.status === 201) {
                zoneChangedCallback();
            }
            console.log("Create is complete");
            return response;
        } catch (error) {
            console.error('Error adding zone:', error);
            return null;
        }
    }

    async function handleDeleteZone(zoneId) {
        setIsConfirmDeleteOpen(false);
        try {
            const response = await backendAPI.deleteZone(zoneId);
            if (response.status === 200) {
                zoneChangedCallback();
            }
        } catch (error) {
            console.error('Error deleting zone:', error);
        }
    }

    const handleShowConfirmDelete = (zone) => {
        setSelectedZone(zone);
        setIsConfirmDeleteOpen(true);
    };

    function handleWebsocketTransitEvent(data) {
        // console.log("Zones got a transit event:", data);
        // Find the zone that this transit belongs to, and update the stats
        // const zone = zones.find(zone => zone.id == data.zone_id);
        // if (zone) {
        //     const updatedStats = transitStats.map(stat => {
        //         if (stat.zone_id === zone.id) {
        //             if(data.status == 'OPEN') {
        //                 stat.open_transits++;
        //             } else if(data.status == 'CLOSED') {
        //                 stat.closed_transits++;
        //                 stat.open_transits--;
        //             } else if(data.status == 'EXPIRED') {
        //                 stat.open_transits--;
        //             }
        //         }
        //         return stat;
        //     });
        //     setTransitStats(updatedStats);
        // }
	}

    return (
        <div>
            <Row className="zone-header">
                <Col className="mt-1 mb-1" xs={3} lg={4}><strong>Name</strong></Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}><strong>Open</strong></Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}><strong>Closed</strong></Col>
                <Col className="mt-1 mb-1" xs={2} lg={2}><strong>Incomplete</strong></Col>
                <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={1}></Col>
                <Col className="mt-1 mb-1" xs={3} lg={1}>
                    <ZoneSettingsModal 
                        zone={null} 
                        submit_callback={handleAddZone} 
                        icon={faPlus} 
                        title="Add Zone" 
                        backendAPI={backendAPI}
                    />
                </Col>
            </Row>
            {zones.map((zone) => (
                <Row key={zone.id} className="zone-row">
                    <Col className="text-truncate d-block mt-1 mb-1" xs={3} lg={4}>{zone.name}</Col>
                    <Col className="mt-1 mb-1" xs={2} lg={2}>{transitStats.find(stat => stat.zone_id === zone.id)?.open_transits || 0}</Col>
                    <Col className="mt-1 mb-1" xs={2} lg={2}>{transitStats.find(stat => stat.zone_id === zone.id)?.closed_transits || 0}</Col>
                    <Col className="mt-1 mb-1" xs={2} lg={2}>{transitStats.find(stat => stat.zone_id === zone.id)?.incomplete_transits || 0}</Col>
                    <Col className="d-none d-sm-inline mt-1 mb-1" xs={0} lg={1}></Col>
                    <Col className="mt-1 mb-1" xs={3} lg={1}>
                        <Row>
                            <Col className="mt-1 mb-1" xs={3} lg={3}>
                                <ZoneSettingsModal
                                    zone={zone}
                                    submit_callback={handleEditZone}
                                    icon={faEdit}
                                    title="Edit Zone"
                                    backendAPI={backendAPI}
                                />
                            </Col>
                            <Col className="mt-1 mb-1" xs={3} lg={1}>
                                <FontAwesomeIcon icon={faTrash} style={{ color: "red", cursor: "pointer"}} onClick={() => handleShowConfirmDelete(zone)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ))}
            {selectedZone && (
                <ConfirmationDialog
                    title="Delete Zone?"
                    body={`Are you sure you want to delete the zone: ${selectedZone.name}?`}
                    show={isConfirmDeleteOpen}
                    onHide={() => setIsConfirmDeleteOpen(false)}
                    onConfirm={() => handleDeleteZone(selectedZone.id)}
                />
            )}
        </div>
    );
}

export default ZonesTable;
