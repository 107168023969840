import { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ImageModal from './common/ImageModal.js';

// export function TransitModal({ show, onHide, backendAPI, zoneId }) {
export function TransitModal({ zone, backendAPI, transit }) {

    const [showModal, setShowModal] = useState(false);
    const showModalRef = useRef(showModal);

    // const [transitDetails, setTransitDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [anpr_image, setANPRImage] = useState('');
    // const [overview_image, setOverviewImage] = useState('');
    const [entry_anpr_image_loading, setEntryANPRImageLoading] = useState(true);
    const [entry_overview_image_loading, setEntryOverviewImageLoading] = useState(true);
    const [exit_anpr_image_loading, setExitANPRImageLoading] = useState(true);
    const [exit_overview_image_loading, setExitOverviewImageLoading] = useState(true);

    const [entry_anpr_image, setEntryANPRImage] = useState('');
    const [entry_overview_image, setEntryOverviewImage] = useState('');
    const [exit_anpr_image, setExitANPRImage] = useState('');
    const [exit_overview_image, setExitOverviewImage] = useState('');

    // useEffect(() => {
    //     if (show && zoneId) {
    //         fetchZoneTransits(zoneId);
    //     }
    // }, [show, zoneId]);

    // async function fetchZoneTransits(zoneId) {
    //     setLoading(true);
    //     try {
    //         const response = await backendAPI.getTransits({ zone: zoneId });
    //         console.log('Transit response:', response);
    //         if (response.data && response.data.zone_transits) {
    //             setTransitDetails(response.data.zone_transits);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching zone_transits for zone:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    async function updateANPRImage(media_id, loading_fn, update_fn) {
        if(media_id == 'None') {
            update_fn(null);
            loading_fn(false);
            return;
        }
        loading_fn(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    update_fn(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting ANPR image:', error);
                    update_fn(null);
                }
            }
        }
        loading_fn(false);
    }

    async function updateOverviewImage(media_id, loading_fn, update_fn) {
        if(media_id == 'None') {
            update_fn(null);
            loading_fn(false);
            return;
        }
        loading_fn(true);
        if (media_id) {
            const response = await backendAPI.getMedia(media_id);
            if (response.data && response.data.media[0].image) {
                try {
                    update_fn(response.data.media[0].image);
                } catch (error) {
                    console.error('Error getting overview image:', error);
                    update_fn(null);
                }
            }
        }
        loading_fn(false);
    }

    async function openModal() {
        setShowModal(true);
        if (transit) {
            // setTransit(transit);
            // console.log(transit);
            if(transit.entry_device_event) {
                updateANPRImage(transit.entry_device_event.anpr_image_media_id, setEntryANPRImageLoading, setEntryANPRImage);
                updateOverviewImage(transit.entry_device_event.overview_image_media_id, setEntryOverviewImageLoading, setEntryOverviewImage);
            } else {
                setEntryANPRImageLoading(false);
                setEntryOverviewImageLoading(false);
            }

            if(transit.exit_device_event) {
                updateANPRImage(transit.exit_device_event.anpr_image_media_id, setExitANPRImageLoading, setExitANPRImage);
                updateOverviewImage(transit.exit_device_event.overview_image_media_id, setExitOverviewImageLoading, setExitOverviewImage);
            } else {
                setExitANPRImageLoading(false);
                setExitOverviewImageLoading(false);
            }
        }
    }

    function closeModal() {
        setShowModal(false);
    }

    function getBestClass(entry, exit) {
        if(entry && entry.vehicle_class && exit && exit.vehicle_class) {
            if(entry.vehicle_class_confidence > exit.vehicle_class_confidence) {
                return entry.vehicle_class;
            } else {
                return exit.vehicle_class;
            }
        } else if(entry && entry.vehicle_class) {
            return entry.vehicle_class;
        } else if(exit && exit.vehicle_class) {
            return exit.vehicle_class;
        }

        return '---';
    }

    return (
        <div style={{ float: "left" }}>
            <FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", marginRight: '20px' }} onClick={() => openModal()} />
            <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Transit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : transit != null ? (
                        <Row>
                            <Row>
                                <Col><strong>Zone: </strong> {transit.zone_name}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Entry VLN: </strong> 
                                    {transit.entry_device_event ? transit.entry_device_event.vln : "---"}
                                </Col>
                                <Col>
                                    <strong>Exit VLN: </strong> 
                                    {transit.exit_device_event ? transit.exit_device_event.vln : "---"}
                                </Col>
                            </Row>
                            <Row>
                                <Col><strong>Status:</strong> {transit.status}</Col>
                                <Col>
                                    <strong>Duration:</strong> {transit.entry_device_event && transit.exit_device_event ? 
                                        Math.round((new Date(transit.exit_device_event.event_time) - new Date(transit.entry_device_event.event_time)) / 60000) + ' minutes' : '---'}
                                </Col>
                            </Row>
                            <Row>
                                <Col><strong>Entry: </strong>{transit.entry_device_event ? transit.entry_device_event.event_time : '---'}</Col>
                                <Col><strong>Exit: </strong>{transit.exit_device_event ? transit.exit_device_event.event_time : '---'}</Col>
                            </Row>
                            <Row>
                                <Col><strong>Class: </strong>
                                    {
                                        getBestClass(transit.entry_device_event, transit.exit_device_event)
                                    }
                                </Col>
                            </Row>
                            {transit.admin_matched_time && 
                                <Row>
                                    <Col><strong>Matched by admin: </strong>{transit.admin_matched_time}</Col>
                                </Row>
                            }
                            <hr className="my-3" />
                            <Row>
                                <center><strong>ENTRY</strong></center>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {entry_overview_image_loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : entry_overview_image ? (
                                            <ImageModal imageSrc={`data:image/jpeg;base64,${entry_overview_image}`} altText="Entry Overview">
                                                <img src={`data:image/jpeg;base64,${entry_overview_image}`} style={{ width: '100%' }} alt="Not available" />
                                            </ImageModal>
                                        ) : (
                                            <p>Overview image not available</p>
                                        )}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {entry_anpr_image_loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : entry_anpr_image ? (
                                            <img src={`data:image/jpeg;base64,${entry_anpr_image}`} style={{ width: '100%' }} alt="Not available" />
                                        ) : (
                                            <p>ANPR image not available</p>
                                        )}
                                    </Col>
                                </Row> */}
                            </Row>
                            <hr className="my-3" />
                            <Row>
                                <center><strong>EXIT</strong></center>
                                <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {exit_overview_image_loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : exit_overview_image ? (
                                            <ImageModal imageSrc={`data:image/jpeg;base64,${exit_overview_image}`} altText="Entry Overview">
                                                <img src={`data:image/jpeg;base64,${exit_overview_image}`} style={{ width: '100%' }} alt="Not available" />
                                            </ImageModal>
                                        ) : (
                                            <p>Overview image not available</p>
                                        )}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="mt-1 mb-1" xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {exit_anpr_image_loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        ) : exit_anpr_image ? (
                                            <img src={`data:image/jpeg;base64,${exit_anpr_image}`} style={{ width: '100%' }} alt="Not available" />
                                        ) : (
                                            <p>ANPR image not available</p>
                                        )}
                                    </Col>
                                </Row> */}
                            </Row>
                        </Row>
                    ) : (
                        <p>No live transits available for this zone.</p>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default TransitModal;
